import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Mobile, TabletAndDesktop } from "../../theme/media";
import RelatedNewsSitesMobile from "./RelatedNewsSitesMobile";
import RelatedNewsSitesDesktop from "./RelatedNewsSitesDesktop";

const RelatedNewsSites = ({ newsSite, newsSites }) => {
  const { narrative, fundings, relations } = newsSite;

  const relationTypeIds = relations.map(r => r.type.uuid);
  const fundingIds = fundings.map(f => f.uuid);

  const byNarrative = newsSites.filter(
    site => site.narrative.uuid === narrative.uuid
  );
  const byFunding = newsSites.filter(site =>
    site.fundings.find(funding => fundingIds.includes(funding.uuid))
  );
  const byRelation = newsSites.filter(site =>
    site.relationTypes.find(r => relationTypeIds.includes(r.uuid))
  );

  return (
    <>
      <Mobile>
        <RelatedNewsSitesMobile
          byNarrative={byNarrative}
          byFunding={byFunding}
          byRelation={byRelation}
        />
      </Mobile>
      <TabletAndDesktop>
        <RelatedNewsSitesDesktop
          byNarrative={byNarrative}
          byFunding={byFunding}
          byRelation={byRelation}
        />
      </TabletAndDesktop>
    </>
  );
};

RelatedNewsSites.defaultProps = {
  className: null,
};

RelatedNewsSites.propTypes = {
  /** For extending styles */
  className: PropTypes.string,
};

export default styled(React.memo(RelatedNewsSites))``;
