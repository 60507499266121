import styled from "styled-components";

import { media } from "../../theme/media";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${media.tablet`
    grid-template-columns: repeat(${props => props.columns || 3}, 1fr);
  `}
`;

export default Grid;
