import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import NewsSiteList from "./NewsSiteList";
import Grid from "../layout/Grid";

const Content = styled.div``;

const RelatedNewsSitesDesktop = ({
  className,
  byNarrative,
  byFunding,
  byRelation,
}) => {
  return (
    <Content className={className}>
      <Grid>
        <div>
          <h3>Fundings</h3>
          <NewsSiteList
            newsSites={byFunding}
            emptyText="No shared fundings with other sites"
          />
        </div>
        <div>
          <h3>Relations</h3>
          <NewsSiteList
            newsSites={byRelation}
            emptyText="No shared relations with other sites"
          />
        </div>
        <div>
          <h3>Narrative</h3>
          <NewsSiteList
            newsSites={byNarrative}
            emptyText="No shared narrative with other sites"
          />
        </div>
      </Grid>
    </Content>
  );
};

RelatedNewsSitesDesktop.defaultProps = {
  className: null,
};

RelatedNewsSitesDesktop.propTypes = {
  /** For extending styles */
  className: PropTypes.string,
};

export default styled(React.memo(RelatedNewsSitesDesktop))``;
