import React from "react";
import styled from "styled-components";

const Content = styled.span``;

const Funding = ({ className, funding }) => {
  return <Content className={className}>{funding.name}</Content>;
};

export default styled(React.memo(Funding))``;
