import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Content = styled.div``;

const StyledLink = styled(Link)`
  display: inline-block;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const Relations = ({ className, relations }) => {
  return (
    <Content className={className}>
      {relations.length > 0 ? (
        <ul>
          {relations.map(relation => (
            <li key={relation.uuid}>
              <StyledLink to={`/relation-types/${relation.type.uuid}`}>
                {relation.type.name}
              </StyledLink>
              <p>{relation.notes}</p>
            </li>
          ))}
        </ul>
      ) : (
        <h3>No known relations</h3>
      )}
    </Content>
  );
};

Relations.defaultProps = {
  className: null,
};

Relations.propTypes = {
  /** For extending styles */
  className: PropTypes.string,
};

export default styled(React.memo(Relations))``;
