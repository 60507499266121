import React, { useState } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import Row from "../layout/Row";
import Button from "../common/Button";
import NewsSiteList from "./NewsSiteList";

const Content = styled.div`
  ${Row} {
    margin: 2rem 0;
  }
`;

const RelatedNewsSitesMobile = ({
  className,
  byNarrative,
  byFunding,
  byRelation,
}) => {
  const fundingEmpty = byFunding.length === 0;
  const relationEmpty = byRelation.length === 0;

  const [index, setIndex] = useState(
    !fundingEmpty ? 0 : !relationEmpty ? 1 : 2
  );
  const list = [byFunding, byRelation, byNarrative];
  return (
    <Content className={className}>
      <Row justify="space-between">
        <Button
          active={index === 0}
          type="button"
          onClick={() => setIndex(0)}
          invert
        >
          Fundings
        </Button>
        <Button
          active={index === 1}
          type="button"
          onClick={() => setIndex(1)}
          invert
        >
          Relations
        </Button>
        <Button
          active={index === 2}
          type="button"
          onClick={() => setIndex(2)}
          invert
        >
          Narrative
        </Button>
      </Row>
      <NewsSiteList newsSites={list[index]} />
    </Content>
  );
};

RelatedNewsSitesMobile.defaultProps = {
  className: null,
};

RelatedNewsSitesMobile.propTypes = {
  /** For extending styles */
  className: PropTypes.string,
};

export default styled(React.memo(RelatedNewsSitesMobile))``;
