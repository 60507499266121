import React from "react";
import styled from "styled-components";

const Content = styled.div`
  margin-top: 4rem;
`;

const Description = styled.p``;

const Narrative = ({ className, narrative }) => {
  return (
    <Content className={className}>
      <h2>{narrative.name}</h2>
      <Description>{narrative.description}</Description>
    </Content>
  );
};

export default styled(React.memo(Narrative))``;
