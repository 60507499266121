import React from "react";
import styled from "styled-components";
import { media } from "../../theme/media";

import Section from "../layout/Section";
import Narrative from "../narrative/Narrative";
import Fundings from "../funding/Fundings";
import Relations from "../relation/Relations";
import Markdown from "../common/Markdown";
import Button from "../common/Button";

const SiteHeading = styled.h1`
  font-weight: 700;
`;

const SiteLink = styled.a`
  display: block;
  margin-bottom: 1rem;
`;

const SectionHeading = styled.h2`
  font-size: 1.125rem;
  text-transform: uppercase;

  ${media.bigPhone`
  font-size: 1.25rem;
  `}
  ${media.tablet`
    font-size: 1.375rem;
  `}
  ${media.desktop`

  `}
  ${media.giant`

  `}
`;

const SectionDescription = styled.p`
  font-size: 0.875rem;
  opacity: 0.5;

  ${media.bigPhone`
  font-size: 1rem;
  `}
  ${media.tablet`
    font-size: 1.125rem;
  `}
  ${media.desktop`
    font-size: 1.25rem;
  `}
  ${media.giant`
    font-size: 1.375rem;
  `}
`;

const RelationSectionDescription = styled(SectionDescription)`
  opacity: 1;
  color: ${({ theme }) => theme.color.text_muted.on_light};
`;

const StyledButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.color.border.white};
`;

const ParticipateSectionHeading = styled(SectionHeading)`
  color: ${props => props.theme.color.text.on_primary};
`;

const ParticipateSectionDescription = styled(SectionDescription)`
  color: ${props => props.theme.color.text.on_primary};
  opacity: 0.9;
`;

const NewsSite = ({ newsSite }) => {
  const {
    name,
    web_site,
    about_us,
    narrative,
    fundings,
    relations,
    location,
  } = newsSite;
  return (
    <>
      <Section>
        <SiteHeading>
          {name} ({location.name})
        </SiteHeading>
        <SiteLink href={web_site} rel="noopener noreferrer" target="_blank">
          {web_site}
        </SiteLink>
        <Markdown markdown={about_us} showAll={false} />
      </Section>
      <Section narrative={narrative.theme}>
        <SectionHeading>Political alignment</SectionHeading>
        <SectionDescription>
          All media organizations are reporting from their own world view. Some
          call this political alignment, some editorial leaning and, and some
          media bias. We call them Narratives.
        </SectionDescription>
        <Narrative narrative={narrative} />
      </Section>
      <Section neutral>
        <SectionHeading>Funding / Ownership</SectionHeading>
        <SectionDescription>
          We’ve done research on who owns or funds the site so you can learn
          easier about possible funding bias or conflicts of interests.
        </SectionDescription>
        <Fundings fundings={fundings} />
      </Section>
      <Section>
        <SectionHeading>Relations</SectionHeading>
        <RelationSectionDescription>
          The reason we show relations to CFR, Bilderberg, Trilateral Commission
          and Chatham House is because there was a&nbsp;
          <a
            href="https://cfrmedia.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            study
          </a>
          &nbsp;made by Swiss Propaganda Research. They found out that many
          western news media have relations to those organizations.
        </RelationSectionDescription>
        <Relations relations={relations} />
      </Section>
      <Section minimal primary>
        <ParticipateSectionHeading>Participate</ParticipateSectionHeading>
        <ParticipateSectionDescription>
          Help us develop the content futher by submitting new information.
        </ParticipateSectionDescription>
        <StyledButton
          invert
          onClick={() => {
            window.location.href = `mailto:shoulditrustmedia@protonmail.com?subject=Should I Trust Media: Suggesting correction to ${newsSite.name}`;
          }}
        >
          Suggest Correction
        </StyledButton>
      </Section>
    </>
  );
};

export default NewsSite;
