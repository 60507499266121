import React from "react";
import styled from "styled-components";

import Layout from "../components/layout/Layout";
import Section from "../components/layout/Section";
import Header from "../components/layout/Header";
import NewsSite from "../components/news-site/NewsSite";
import SelectNewsSite from "../components/news-site/SelectNewsSite";
import RelatedNewsSites from "../components/news-site/RelatedNewsSites";

const StyledSelectNewsSite = styled(SelectNewsSite)`
  flex: 1;
  margin-left: 2rem;
  max-width: 400px;
`;

const NewsSitePage = ({ pageContext }) => {
  const { newsSites, newsSite } = pageContext;
  const { slug } = newsSite;

  const relatedNewsSites = newsSites.filter(n => n.uuid !== newsSite.uuid);
  return (
    <Layout title={newsSite.name}>
      <Section minimal>
        <Header>
          <StyledSelectNewsSite newsSites={newsSites} value={slug} />
        </Header>
      </Section>
      <NewsSite newsSite={newsSite} />
      <Section dark>
        <h2>Similar News Sites</h2>
        <RelatedNewsSites newsSite={newsSite} newsSites={relatedNewsSites} />
      </Section>
    </Layout>
  );
};

export default NewsSitePage;
