import React from "react";
import styled from "styled-components";
import groupBy from "lodash/groupBy";
import keys from "lodash/keys";
import first from "lodash/first";
import { Link } from "gatsby";

import Funding from "./Funding";
import Markdown from "../common/Markdown";
import { media } from "../../theme/media";

const Content = styled.div``;

const Box = styled.div`
  padding: 1rem 0;

  ${Markdown} {
    opacity: 0.5;
  }

  & + & {
    margin-top: 1.5rem;
  }
`;

const List = styled.ul`
  columns: 1;

  ${media.tablet`
    columns: 3;
  `}
`;

const Item = styled.li`
  & + & {
    margin-top: 1.5rem;
  }
`;

const StyledLink = styled(Link)`
  font-size: 1.125rem;
  line-height: 1.2;

  ${media.tablet`
    font-size: 1.25rem;
  `}
`;

const Fundings = ({ className, fundings }) => {
  const byType = groupBy(fundings, f => f.type.name);
  const noData = fundings.length === 0;
  return (
    <Content className={className}>
      {noData ? (
        <p>
          The site doesn&apos;t disclose its ownership or funding on their
          website.
        </p>
      ) : (
        <>
          {keys(byType).map(type => {
            const firstFunding = first(byType[type]);
            return (
              <Box key={type}>
                <h3>{type}</h3>
                <Markdown markdown={firstFunding.type.description} />
                <List>
                  {byType[type].map(funding => (
                    <Item key={funding.uuid}>
                      <StyledLink to={`/funding/${funding.uuid}`}>
                        <Funding funding={funding} />
                      </StyledLink>
                    </Item>
                  ))}
                </List>
              </Box>
            );
          })}
        </>
      )}
    </Content>
  );
};

export default styled(React.memo(Fundings))``;
